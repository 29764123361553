<template>
    <div class="container">
        <!-- ATUALIZAÃO DE PROBLEMA 17/04/2022
            1. (Solved) QUANDO UMA TAG É ADICIONADA AO FIREBASE ELA FICA NA TABELA, ENTRETANTO QUANDO UMA TAG É EXCLUÍDA NO FIREBASE, ELA NÃO SAI DA TABELA
            2. (Solved) RESOLVER PROBLEMA DA QUANTIDADE DE SOLICITAÇOES QUANDO FOR ESCOLHIDO UMA BASE DE LIVROS
            RECOMENDA-SE ULTILIZAR PAGINAÇAO DINÂMICA, ONDE CADA INTERVALO DE DA LISTA É SOLIITADO EM "LAZYLOAD". O ÚNICO PROBLEMA
            É QUE SE FAZ NECESSARIO UM ÍNDICE PARA ELABORAR AS PAGINAÇOES. ENTRETANTO, PODEMOS ARMAZENAR UMA VARIÁVEL COM O NÚMERO DE LIVROS
            DA BASE E FAZER O CÁLCULO COM ELA.
            3. ELABORAR UM NOVO LAYOUT OU PARA O MODAL OU ALGO QUE NÃO SEJA EM LISTA, MAS O O USUÁRIO POSSA IR CLICANDO EM PRÓXIMO.
            4. (Solved) ADICIONAR UM MODAL PARA INFORMA QUE AS ALTERAÇOES FEITAS NO LIVRO FORAM SALVAS
         -->
        <h1>Editor de Tags - Acervo UNINOVE {{info}}</h1>
        <p v-for="i in info" :key="i.id">{{i}}</p>
        <div class="row my-1">
            <b-button class="m-1" @click="mudaBaseDados('ck')" variant="outline-primary" style="width: fit-content">Clinical Key: 1052</b-button>
            <b-button class="m-1" @click="mudaBaseDados('pe')" variant="outline-primary" style="width: fit-content">Pearson: 11.103</b-button>
            <b-button class="m-1" @click="mudaBaseDados('mb')" variant="outline-primary" style="width: fit-content">Minha Biblioteca: 9026</b-button>
            <b-button class="m-1" disabled  variant="outline-primary" style="width: fit-content">O'Reilly</b-button>
            <b-button class="m-1" @click="rodarContadorGeral()" variant="outline-primary" style="width: fit-content">Rodar Contador Geral</b-button>
        </div>
        
        <hr>

        <!-- Modal -->
        <b-modal id="modal-livro" size="xl" :title="modal.titulo">
            <div class="row">
                <div class="col-2">
                    <img :src="modal.imagem" height="160px">
                </div>
                <div class="col">
                    <p><strong>mms_id: </strong>{{modal.mms_id}}</p>
                    <p><strong>Descrição: </strong>{{modal.descricao}}</p>
                    <a :href="modal.link_acesso"><strong>Acesso > </strong></a>
                </div>
            </div>
            <hr>
            <div class="row">
                <h6>Tags do Livro:</h6>
                <b-badge pill v-for="tag in modal.tags" :key="tag.id" class="m-1" variant="secondary" style="width: fit-content">
                    {{tag}}
                </b-badge>
            </div>
            <hr>
            <div class="row">
                <div class="col-8">
                    <h6>Tags UNINOVE que estão no livro:</h6>
                </div>
                <div class="col-4" style="text-align: right;">
                    <a @click="unTagAll()">Remover todas as tags</a>
                </div>
                <div>
                    <b-button size="sm" pill v-for="(btn, idx) in tag_matriz_botoes" :key="idx" :pressed.sync="btn.state" class="m-1" variant="outline-primary" style="width: fit-content">
                        {{ btn.caption }}
                    </b-button>
                </div>
            </div>
            <template #modal-footer="{ cancel }">
                <div class="w-100">
                    <b-button variant="primary" class="float-right m-1" @click="atualizaTag(modal.mms_id, modal.tags)">Salvar</b-button>
                    <b-button variant="danger" class="float-right m-1" @click="cancel()">Cancelar</b-button>
                </div>
            </template>
        </b-modal>

        <!-- Tabela -->
        <ve-table :key="key_atualizacao" ref="tabela_livros_tags" :columns="columns" :table-data="tableData" row-key-field-name="index"></ve-table>
        <div class="table-pagination">
            <ve-pagination :total="totalCount" :page-index="pageIndex" :page-size="pageSize" @on-page-number-change="pageNumberChange" @on-page-size-change="pageSizeChange"/>
        </div>
    </div>
</template>

<script>
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import tags_lista from '@/store/categorias.js'
import firebase from "firebase/app";
// import axios from 'axios'
import "firebase/analytics";
import "firebase/auth";
import 'firebase/database';
import 'firebase/firestore';

// Inicializando o Firebase
var app = firebase.app();
firebase.analytics();
var db = firebase.firestore(app);

export default {
    components: {
    },
    data() {
        return {
            // Variáveis de paginação da easy table
            pageIndex: 1,
            info: "",
            pageSize: 10,
            curso_escolhido: "",
            base_escolhida: "mb",
            loadingInstance: null,
            num_startAt: 0,
            num_limit: 50,
            first_book: 0,
            last_book: 0,
            previous_start_points: [],
            acervo: [],
            acervo_firebase_completo_tamanho: [],
            key_atualizacao: 0,
            tags_uninove: tags_lista.lista,
            tag_matriz_botoes: [], // { caption: 'Toggle 1', state: true }
            modal: {
                titulo: '',
                imagem: '',
                mms_id: '',
                link_acesso: '',
                descricao: '',
                tags: []
            },
            columns: [
                        {field: "", key: "a", title: "", align: "center", type: "expand",
                            // renderBodyCell: ({  rowIndex }, ) => {
                            //     return (this.pageIndex - 1) * this.pageSize + rowIndex + 1;
                            // },
                        },
                        {field: "index", key: "b", title: "Index", align: "left",},
                        {field: "base_dados", key: "c", title: "Base", align: "center",},
                        {field: "mms_id", key: "d", title: "mms_id", align: "center"},
                        {field: "titulo", key: "e", title: "Título", align: "right",},
                        {field: "tags_area", key: "f", title: "Tags_area", align: "right", type: "array",
                            renderBodyCell: (
                                    { row, column }) => {
                                        const cellData = row[column.field];
                                        // console.log(cellData)
                                        // let y = cellData.map(x => { return  <strong>  </strong>})
                                        return cellData.map((tag) => {
                                            // console.log(this.analizaSeTagUninove(tag))
                                            if (this.analizaSeTagUninove(tag) >= 0){
                                                // se index > 0 então a tag é da uninove
                                                return (
                                                    <span class="badge bg-primary mx-1">
                                                        {tag}
                                                    </span>
                                                );
                                            } else {
                                                return (
                                                <span class="badge bg-secondary mx-1">
                                                    {tag}
                                                </span>
                                            );
                                            }
                                            
                                        });
                                   }
                        },
                        {
                            field: "",
                            key: "g",
                            title: "Editar",
                            align: "right",
                            renderBodyCell: ({ row }) => {
                            return (
                                <span>
                                    <b-button variant="outline-primary" on-click={() => this.showModalLivro(row)}>
                                        <b-icon icon="pencil-square"></b-icon>
                                    </b-button>
                                </span>
                                );
                            }
                        }
                    ],
            // tableData: this.acervo,
        }
    },
    computed:{
        btnStates() {
            return this.tag_matriz_botoes.map(btn => btn.state)
        },

        tableData(){
            return this.acervo
        },

        // total count
        totalCount() {
            return this.acervo_firebase_completo_tamanho[1] - this.acervo_firebase_completo_tamanho[0];
        },
    },
    watch: {
        btnStates: function(){
            // function(newValue,oldValue)

            // Percorra todas as tags da matriz
            this.tag_matriz_botoes.forEach(x => {
                // Essa tag é verdadeira?
                let tagIsTrue = x.state
                // Essa tag está no array do livro
                let tagIsInBookArray = this.modal.tags.includes(x.caption)
                
                if (tagIsTrue && tagIsInBookArray){ 
                    // Se a tag da matriz for verdadeira e estiver nas tags do livro, não faça nada.

                } else if (tagIsTrue && !tagIsInBookArray) { 
                    // Se a tag da matriz for verdadeira e não estiver nas tags do livro, adicione.
                    // console.log('Adicionando a tag: ', x.caption)
                    // return this.modal.tags.push(x.caption)
                    return this.modal.tags = this.modal.tags.concat(x.caption) 

                } else if (!tagIsTrue && tagIsInBookArray) { 
                    // Se a tag da matriz for falsa e estiver nas tags do livro, remova.
                    console.log('Removendo a tag: ', x.caption)
                    return this.modal.tags = this.modal.tags.filter(y => y != x.caption)

                } else if (!tagIsTrue && !tagIsInBookArray) { 
                    // Se a tag da matriz for falsa e não estiver nas tags do livro, não faça nada.

                }
            })
        }
    },
    methods: {
        retornaLivrosFirebasePorCurso: function(curso, partida, limite){
            // console.log(curso, typeof curso, this.num_startAt, typeof this.num_startAt, this.num_limit, typeof this.num_limit)
            let respostaRaw = [];
            // .collection("acervo-completo").where("tags_area", "array-contains", "medicina")
            db.collection("acervo-completo").where("tags_area", "array-contains", curso)
            .orderBy("index")
            .startAt(parseInt(partida))
            .limit(parseInt(limite))
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    respostaRaw.push(doc.data())
                });
                this.acervo = respostaRaw
                console.log(this.acervo)
                // Atualiza o elemento. Essa key garante que o elemento inteiro seja atualizado, de forma que ele reinicie
                this.key_atualizacao++
                this.loadingClose()
            })
            .catch((error) => {
                console.log("Não foi possivel retornar os resultados solicitados para o curso: ", curso, ". Erro: ", error);
            });
        },

        retornaLivrosFirebasePorBase: function(){
            // Nessa função retornamos os resultados visíveis na tabela. 
            // Desta forma precisamos retornar os resultados somente sobre demanda (lazyload)
            this.acervo = []
            this.alteraTamanhoIndexPorBase(this.base_escolhida)
            let primeiro_index_firebaseDB = this.acervo_firebase_completo_tamanho[0]
            console.log('primeiro_index_firebaseDB: ',primeiro_index_firebaseDB)
            let primeiro_resultado = (this.pageIndex - 1) * this.pageSize
            
            let respostaLivrosPorBase = [];
            db.collection("acervo-completo").where("base_dados", "==", this.base_escolhida)
            .orderBy("index")
            .startAt(parseInt(primeiro_resultado + primeiro_index_firebaseDB))
            .limit(parseInt(this.pageSize))
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    respostaLivrosPorBase.push(doc.data())
                }); 
                this.acervo = respostaLivrosPorBase
                console.log(this.acervo)
                // Atualiza o elemento. Essa key garante que o elemento inteiro seja atualizado, de forma que ele reinicie
                this.key_atualizacao++
                this.loadingClose()
            })
            .catch((error) => {
                console.log("Não foi possivel retornar os resultados solicitados para a base: ", this.base_escolhida, ". Erro: ", error);
            });
        },

        addCounter: function(curso, livro_mms_id){
            db.collection("contadores").doc(curso.toString())
            .update({
                livros: firebase.firestore.FieldValue.arrayUnion(livro_mms_id)
            })
            .then(() => {
                console.log('Adicionado o livro ',livro_mms_id, ' para: ', curso)
            })
            .catch((error) => {
                console.error(error);
            })
        }, 

        reduceCounter: function(curso, livro_mms_id){
            db.collection("contadores").doc(curso.toString())
            .update({
                livros: firebase.firestore.FieldValue.arrayRemove(livro_mms_id)
            })
            .then(() => {
                console.log('Removido o livro ',livro_mms_id, ' de: ', curso)
            })
            .catch((error) => {
                console.error(error);
            })
        }, 

        changeCounter(livro_mms_id){
            // ache o livro do modal no acervo antigo e diga quais eram as tags antigas
            let tags_antigas = this.acervo.filter(x => x.mms_id == this.modal.mms_id)[0].tags_area.map(x => x);
            let tags_novas = this.modal.tags.map(x => x);
            let tags_que_serao_adicionadas = tags_novas.filter(i => !tags_antigas.includes(i));
            let tags_que_serao_excluidas = tags_antigas.filter(i => !tags_novas.includes(i));

            // console.log('Tags antigas: ', tags_antigas)
            // console.log('Tags novas: ', tags_novas)
            // console.log('Tags que serão adicionadas: ', tags_que_serao_adicionadas)
            // console.log('Tags que serão excluidas: ', tags_que_serao_excluidas)

            tags_que_serao_adicionadas.forEach(x => this.addCounter(x, livro_mms_id))
            tags_que_serao_excluidas.forEach(x => this.reduceCounter(x, livro_mms_id))

        },

        rodarContadorGeral(){
            db.collection("acervo-completo")
            .orderBy("index")
            .startAt(23000)
            .limit(2000)
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    let livro_atual = doc.data()

                    livro_atual.tags_area.forEach(x => {
                        // Analiza primeiro se é tag uninove para adicionar nos contadores
                        if(this.analizaSeTagUninove(x) >= 0){
                            this.addCounter(x.trim(), livro_atual.mms_id)
                            // console.log('O livro: ', livro_atual.mms_id , ' foi adicionado na lista: ', x)
                        } else {
                            console.log('Não é tag uninove')
                        }
                        
                    })
                    
                    
                }); 
            })
            .catch((error) => {
                console.log("Não foi possivel retornar os resultados solicitados para a base: ", this.base_escolhida, ". Erro: ", error);
            });
        },

        analizaSeTagUninove: function(tag){
            // Essa função verifica se é TAG uninove e retorna true ou false
            return this.tags_uninove.findIndex(x => x.path === tag.trim())
        },

        retornaMatrizSeTagUninove: function(array_tags){
            // Essa função verifica se um array é está presenta na tags uninove e retorna uma matriz na lista completa da uninove em bool
            return this.tags_uninove.map(x => array_tags.includes(x.path))
        },

        showModalLivro(dados_modal){
            // console.log('Dados do modal: ', dados_modal)
            this.modal.titulo = dados_modal.titulo
            this.modal.imagem = dados_modal.link_capa
            this.modal.mms_id = dados_modal.mms_id
            this.modal.link_acesso = dados_modal.link_acesso
            this.modal.descricao = dados_modal.descricao
            this.modal.tags = dados_modal.tags_area
            this.tag_matriz_botoes = [] // Zero o array dos pushes dos livros anteriores
            
            // Vejo quais tags da UNINOVE estão no array e converto para os botões na forma de true ou false
            this.retornaMatrizSeTagUninove(dados_modal.tags_area).forEach((x,index) =>{
                this.tag_matriz_botoes.push({'caption':  this.tags_uninove[index].path, 'state': x})
            })
            // console.log(this.tag_matriz_botoes)
            this.$bvModal.show('modal-livro')
        },
    
        updateTagsLivroFirebase(mms_id, tags_para_substituir){
            // Atenção essa função substitui todas as tags pelo valor inputado. Então formate o array com as tags antigas para elas não se perderem
            console.log('O livro com o mms_id: '+ mms_id+ ' terá as tags alteradas para: ' + tags_para_substituir)
        },

        showLoading() {
            this.loadingInstance.show();
        },

        loadingClose() {
            this.loadingInstance.close();
        },

        atualizaTag: function(mms_id, array_tags_para_update){
            // console.log('mms_id', mms_id, typeof mms_id)
            // console.log('array_tags_para_update', typeof array_tags_para_update)
            db.collection("acervo-completo").doc(mms_id.toString())
            .update({
                "tags_area": array_tags_para_update
            })
            .then(() => {
                this.changeCounter(mms_id)
                console.log("O documento mms_id: ", mms_id, " tem as seguintes tags agora: ", array_tags_para_update);
                // Atualizo as tags direto na tabela para não precisar atualizar a página
                this.acervo.find(x => x.mms_id === mms_id).tags_area = array_tags_para_update
                // Informo ao usuário que as tags foram alteradas
                alert("Alterações salvas com sucesso!!!")
            })
            .catch((error) => {
                // The document probably doesn't exist.
                console.error("Não foi possível documento mms_id: ", mms_id, " receber as tags: ", array_tags_para_update, error);
            });
            
        },

        getTag: function(mms_id){
            db.collection("acervo-completo").doc(mms_id)
            .get()
            .then((doc) => {
                if (doc.exists) {
                    return doc.data()
                } else {
                    // doc.data() will be undefined in this case
                    console.log("O documento socilicitado pelo mms_id: ", mms_id, " não existe");
                }
            })
            .catch((error) => {
                console.log("Não foi possivel retornar o livro solicitados para o mms_id: ", mms_id, ". Erro: ", error);
            });
        },

        unTagAll() {
            this.tag_matriz_botoes.map(x => x.state = false)
        },

        atualizaItemDaTabelaPosUpdate: function(){
            this.acervo[0].tags = prompt("altere a variavel")
        },

        // page number change
        pageNumberChange(pageIndex) {
            this.pageIndex = pageIndex;
            this.retornaLivrosFirebasePorBase()
        },

        // page size change
        pageSizeChange(pageSize) {
            this.pageIndex = 1;
            this.pageSize = pageSize;
            this.retornaLivrosFirebasePorBase()
        },

        alteraTamanhoIndexPorBase(base){
            // Retorna [primeiro, ultimo]
            if(base == 'ck') {
                this.acervo_firebase_completo_tamanho = [0,1052]
            } else if (base == 'pe'){
                this.acervo_firebase_completo_tamanho = [1053,12156]
            } else if (base == 'mb'){
                this.acervo_firebase_completo_tamanho = [12157,21183]
            } else {
                console.log('Erro na solicitação do primeiro index da base: ', base) 
            }
        },

        mudaBaseDados(param){
            this.pageIndex = 1; // Dá um problema na paginação se não voltar para a primeira página
            this.base_escolhida = param
            this.retornaLivrosFirebasePorBase()
        }
        
    },
    mounted(){
        this.loadingInstance = this.$veLoading({
            target: document.querySelector("#loading-container"),
            name: "wave",
        });
        this.showLoading();
        this.retornaLivrosFirebasePorBase("mb", this.num_startAt, this.pageSize)
        
        // var config = {
        //     method: 'get',
        //     url: 'https://cors-anywhere.herokuapp.com/https://api-na.hosted.exlibrisgroup.com/almaws/v1/bibs/collections?level=2&apikey=l8xx577505eb657945849c751dae5ba7a3d5',
        //     headers: {
        //         // "access-control-allow-origin": "*",
        //         // // "connection": "keep-alive",
        //         // "content-encoding": "gzip",
        //         // "content-type": "application/json;charset=UTF-8",
        //         // "p3p": "CP=\"IDC DSP COR ADM DEVi TAIi PSA PSD IVAi IVDi CONi HIS OUR IND CNT\"",
        //         // "server": "CA-API-Gateway/9.0",
        //         // // "transfer-encoding": "chunked",
        //         // "vary": "accept-encoding, Accept-encoding",
        //         // "x-exl-api-remaining": "59990",
        //         // "x-final-url": "https://api-na.hosted.exlibrisgroup.com/almaws/v1/bibs/collections?level=2&apikey=l8xx577505eb657945849c751dae5ba7a3d5",
        //         // "x-request-id": "djCHKVTcBB",
        //     },
        // };

        // axios(config)
        // .then(function (response) {
            
        //     console.log(JSON.stringify(response.data));
        //     console.log('1: ',response);
        //     console.log('2: ',response.data);
        //     console.log('3: ',response.status);
        //     console.log('4: ',response.statusText);
        //     console.log('5: ',response.headers);
        //     console.log('6: ',response.config);
        //     this.info = JSON.stringify(response.data)
        // })
        // .catch(function (error) {
        //     if (error.response) {
        //     // The request was made and the server responded with a status code
        //     // that falls out of the range of 2xx
        //     console.log('Erro 1: ',error.response.data);
        //     console.log('Erro 2: ',error.response.status);
        //     console.log('Erro 3: ',error.response.headers);
        //     console.log(error);
        //     } else if (error.request) {
        //     // The request was made but no response was received
        //     // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        //     // http.ClientRequest in node.js
        //     console.log(error.request);
        //     } else {
        //     // Something happened in setting up the request that triggered an Error
        //     console.log('Error', error.message);
        //     }
        //     console.log(error.config);
        // });
    },
}

</script>

<style scoped>
.livro {
	transition: all .3s ease-in-out;
	padding: 10px 0;
}	
.livro:hover {
	transition: all .3s ease-in-out;
	-ms-transform: scale(1.4);
	-webkit-transform: scale(1.4);  
	transform: scale(1.1);
}
.livro img {  
	border-radius: 7px;
	box-shadow: var(--shadow) 0px 3px 8px;
	width: -webkit-fill-available;
}
.livro img:hover {  
	box-shadow: 0px 1px 5px 2px var(--shadow);
}
.categoria-header {
    align-items: center;
    padding-right: 0px !important;
}
.categoria-img{
    border-radius: 50%;
    height: 3rem;
    width: 3rem;
        /* -o-object-fit: cover; */
    /* object-fit: cover; */
    box-shadow: 0 1px 4px 0 rgb(40 29 50 / 20%);
}
.categoria-text{
    text-align: left;
}
.categoria-button{
    text-align: right;
    padding-right: 0px !important;
}
@media screen and (max-width: 500px){
    .categoria-text > h1{
        text-align: left;
        font-size: 1.25rem;
    }
    .categoria-img{
        border-radius: 50%;
        height: 1.5rem;
        width: 1.5rem;
        -o-object-fit: cover;
        object-fit: cover;
        box-shadow: 0 1px 4px 0 rgb(40 29 50 / 20%);
    }
    .btn{
        font-size: 0.6rem;
    }
}


/* BOOTSTRAP TESTE */
/* Extra Small */
@media screen and (min-width: 0px){
	.test{
		border: #32b336 7px solid !important;
	}
	.livro img{
		height: 120px;
	}
}
		/* Small */
@media screen and (min-width: 576px){
	.test{
		border: #ffa600 7px solid !important;
	}
	.livro img{
		height: 150px;
	}
}

/* Medium*/
@media screen and (min-width: 768px){
	.test{
		border: #41a7ff 7px solid !important;
	}
	.livro img{
		height: 170px;
	}
}

/* Large*/
@media screen and (min-width: 992px){
	.test{
		border: yellow 7px solid !important;
	}
	.livro img{
		height: 200px;
	}
}

/* Extra large */
@media screen and (min-width: 1200px){
	.test{
		border: #ff0053 7px solid !important;
	}
	.livro img{
		max-height: 200px;
	}
}
</style>


